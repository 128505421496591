import { React, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm, useWatch } from "react-hook-form";
import useCallAPIWithRefresh from "../../hooks/useCallAPIWithRefresh";
import Ricerca from "./Ricerca";
import RicercaReferente from "./RicercaReferente";
import AuthContext from "../../context/auth-context";
import "./CreaEventi.css";

const CreaEventi = () => {
  const ctx = useContext(AuthContext);
  const [servizio, setServizio] = useState("");
  const [datiEvento, setDatiEvento] = useState({});
  const history = useHistory();

  const [ricerca, setRicerca] = useState({});
  const [ricercaReferente, setRicercaReferente] = useState({});
  const [count, setCount] = useState(0);
  const [countReferente, setCountreferente] = useState(0);
  const [visibileReferente, setVisibileReferente] = useState(true);

  let [risultato, loading, error] = useCallAPIWithRefresh(servizio, datiEvento);
  let [nomiEventi, loadingNomiEventi, errorNomiEventi] = useCallAPIWithRefresh(
    "getNomeEvento",
    ricerca,
    count
  );
  let [nomiReferenti, loadingNomiReferenti, errorNomiReferenti] =
    useCallAPIWithRefresh("getNomeReferente", ricercaReferente, countReferente);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const nomeEventoBreve = useWatch({ control, name: "eventoBreve" });
  const nomeReferente = useWatch({ control, name: "referente" });

  useEffect(() => {
    setRicerca({ ricerca: nomeEventoBreve });
    setCount((prev) => {
      return prev + 1;
    });
  }, [nomeEventoBreve]);

  useEffect(() => {
    if (nomeReferente?.length >= 6) {
      setRicercaReferente({ ricerca: nomeReferente });
      setCountreferente((prev) => {
        return prev + 1;
      });
    }
  }, [nomeReferente]);

  if (!nomeEventoBreve) {
    nomiEventi = [];
  }
  if (!nomeReferente || nomeReferente?.length < 6) {
    nomiReferenti = [];
  }

  const invioForm = (data) => {
    data.eventoBreve = nomeEventoBreve + "_" + (nomiEventi.length + 1);
    const creatore = ctx.user;
    const newdata = { ...data, creatore };
    console.log(newdata);
    setDatiEvento(newdata);
    setServizio("createEvento");
  };

  if (risultato) {
    history.push("/visualizzaeventi");
  }

  console.log("Nome evento breve: ", nomeEventoBreve);
  console.log("Nome referente: ", nomeReferente);

  const seleziona_referente = (nome) => {
    setValue("referente", nome);
    nomiReferenti = [];
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <h1>Creazione nuovo evento</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Form onSubmit={handleSubmit(invioForm)}>
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Form.Group as={Col} controlId="formNrTicket">
                <Form.Label>Nr. Ticket della richiesta</Form.Label>
                <Form.Control
                  {...register("nrTicket", {
                    required: "Si prega di fornire il numero del ticket",
                  })}
                  summer2022
                  type="text"
                  placeholder="Inserire il numero ticket come da OTRS"
                />
                <p className="error_msg">{errors.nrTicket?.message}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formEventoBreve">
              <Form.Label>
                Nome evento breve &nbsp;
                <OverlayTrigger
                  trigger="hover"
                  key="right"
                  placement="right"
                  overlay={
                    <Popover id="test">
                      <Popover.Header as="h3">
                        Vincoli relativi al nome evento breve
                      </Popover.Header>
                      <Popover.Body>
                        <p>
                          <strong>Caratteri ammessi:</strong> Lettere
                          maiuscole/minuscole (controlli sui nomi eventi già
                          esistenti è case insensitive) e numeri.
                        </p>
                        <p>
                          <strong>Lunghezza minima stringa:</strong> 3.
                        </p>
                        <p>
                          <strong>Lunghezza massima stringa:</strong> 10.
                        </p>
                        <p>
                          <strong>
                            Formazione del nome:
                            <br />
                          </strong>{" "}
                          Il sistema ricerca nomi eventi eventualmente già
                          presenti a sistema e nel caso li trovasse, aggiungerà
                          il suffisso "_n" con n numero sequenziale a partire da
                          1 ( utilizzato per il primo evento).
                        </p>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <InfoIcon />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                onBlur={(e) => {
                  console.log("Perso focus");
                }}
                {...register("eventoBreve", {
                  minLength: {
                    value: 5,
                    message: "Minimo 5 lettere e/o numeri",
                  },
                  maxLength: {
                    value: 10,
                    message: "Massimo 10 lettere e/o numeri",
                  },
                  pattern: {
                    value: /[0-9a-zA-Z]{5,10}/,
                    message:
                      "Per il nome breve solo lettere e/o numeri minimo 5, max 10",
                  },
                })}
                type="text"
                placeholder="Inserire nome evento in forma breve"
              />
              {nomiEventi?.length >= 0 && nomeEventoBreve && (
                <div className="containerRicerca">
                  <ul>
                    <Ricerca
                      nomiEventi={nomiEventi}
                      nomeEventoBreve={nomeEventoBreve}
                    />
                  </ul>
                </div>
              )}
              <p className="error_msg">{errors.eventoBreve?.message}</p>
            </Form.Group>

            <Form.Group as={Col} controlId="formReferente">
              <Form.Label>Referente evento</Form.Label>
              <OverlayTrigger
                trigger="hover"
                key="right"
                placement="right"
                overlay={
                  <Popover id="test">
                    <Popover.Header as="h3">
                      Ricerca strutturati su Oracle
                    </Popover.Header>
                    <Popover.Body>
                      <p>
                        <strong>
                          Ricerca e-mail nome.cognome@unipr.it. Dopo aver
                          digitato i primi 6 caratteri viene interrogato il DB
                          Oracle per l'autocompletamento.
                        </strong>
                      </p>
                    </Popover.Body>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
              <Form.Control
                {...register("referente", {
                  required: "Si prega di indicare il referente",
                })}
                type="text"
                placeholder="Inserire il nome del referente"
              />
              {nomiReferenti?.length >= 0 && nomeReferente && (
                <div className="containerRicerca2">
                  <ul>
                    <RicercaReferente
                      onClick={seleziona_referente}
                      nomiReferenti={nomiReferenti}
                    />
                  </ul>
                </div>
              )}
              <p className="error_msg">{errors.referente?.message}</p>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formNrUtenti">
              <Form.Label>Numero utenti per evento</Form.Label>
              <Form.Control
                {...register("nrUtenti", {

                  max: {
                    value: 100,
                    message: "Massimo 100 utenti per evento",
                  },
                })}
                type="number"
                placeholder="Inserire il numero di utenti per questo evento"
              />
              <p className="error_msg">{errors.nrUtenti?.message}</p>
            </Form.Group>

            <Form.Group as={Col} controlId="formScadenza">
              <Form.Label>Scadenza evento</Form.Label>
              <Form.Control
                type="date"
                placeholder=""
                {...register("dataScadenza", {
                  required: "Si prega di indicare la data di scadenza",
                })}
              />
              <p className="error_msg">{errors.dataScadenza?.message}</p>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col className="d-flex justify-content-center">
              <Form.Group as={Col} controlId="formDescrizione">
                <Form.Label>Descrizione evento</Form.Label>
                <Form.Control
                  {...register("descrizione")}
                  className="formDescrizione"
                  as="textarea"
                  placeholder=""
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="d-flex justify-content-center">
              <Button type="submit">Crea nuovo evento</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default CreaEventi;
