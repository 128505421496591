import { React, useState, useContext } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from '@mui/icons-material/Info';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover';
import Tooltip from "react-bootstrap/Tooltip";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import CardCustomAPIToken from "../CardCustomAPIToken";
import useCallAPIWithRefresh from "../../hooks/useCallAPIWithRefresh";
import AuthContext from "../../context/auth-context";
import DettaglioModal from "./dettaglioModal";
import "./VisualizzaEventi.css";

const VisualizzaEventi = (props) => {
  const ctx = useContext(AuthContext);

  let testo = "";
  const [modalObj, setModalObj] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [servizio, setServizio] = useState("getEventi");
  const [reloadCount, setreloadCount] = useState(0);
  let [resObj, loading, error] = useCallAPIWithRefresh(
    servizio,
    {},
    reloadCount
  );

  if (loading === true) {
    testo = <>CARICAMENTO CONTENUTI IN CORSO....</>;
  }

  const apriDettaglio = (obj) => {
    setModalObj(prev => prev = obj);
    setModalShow(true);
  };

  if (resObj) {
    /*     const { apitoken} =
      resObj;
    apiListRef.current.incrementa();
    testo = `Nuovo token generato: ${apitoken}`; */
    testo = [...resObj].reverse().map((obj) => {
      return (
        <>
          <tr key={obj._id} onClick={() => apriDettaglio(obj)}>
            <td>{obj.nrTicket}</td>
            <td>{obj.eventoBreve}</td>
            <td>{obj.nrUtenti}</td>
            <td>{obj.referente}</td>
            <td>{new Date(obj.dataCreazione).toLocaleString("it-IT")}</td>
            <td>{new Date(obj.dataScadenza).toLocaleDateString("it-IT")}</td>
            <td>{obj.creatore}</td>
            <td>{obj.descrizione}</td>
            <td>
              {obj.ack ? new Date(obj.ack).toLocaleString("it-IT") : null}
            </td>
          </tr>
          {/* <DettaglioModal show={modalShow} chiudi={() => setModalShow(false)} eventoBreve={obj.eventoBreve} /> */}
          <div>
          <DettaglioModal onHide={()=> setModalShow(false)} show={modalShow} obj={modalObj} />
          </div>
        </>
      );
    });
  }

  if (error) {
    console.log(error);
  }

  const refreshClickHandler = () => {
    setreloadCount((prev) => {
      return prev + 1;
    });
  };

  console.log(reloadCount);

  const renderTooltipAggiorna = (props) => {
    return (
      <Tooltip id="aggionra-tooltip" {...props}>
        Aggiorna gli eventi
      </Tooltip>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 500 }}
            overlay={renderTooltipAggiorna}
          >
            <RefreshIcon
              className="refreshIcon"
              onClick={refreshClickHandler}
            />
          </OverlayTrigger>
        </Col>
        <Col className="text-end">

        <OverlayTrigger
          trigger="hover"
          key='left'
          placement='left'
          overlay={
            <Popover id='test'>
              <Popover.Header as="h3">Per aggiornare questo elenco di eventi, utilizzare la freccia circolare presente a sinistra</Popover.Header>
              <Popover.Body>
                <p>
                <strong>Notifiche creazione eventi:<br/></strong> Quando lo script di backend avrà creato le utenze per l'evento inserito, comparirà una notifica pop-up di avviso.
                <br/>Inoltre alla creazione delle utenze verrà valorizzato anche il campo Data creazione su AD con relativa data e ora dell'ACK ricevuto dallo script di backend.
                </p>
                <p>
                <strong>Dettagli evento:<br/></strong> Cliccare su una riga per ottenere un riepilogo dell'evento, compresi gli utenti e relative password da comunicare ai richiedenti.
                </p>                
              </Popover.Body>
            </Popover>
          }
        >
            <InfoIcon
            />
        </OverlayTrigger>


        </Col>
     
        
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <h1>Tutti gli eventi creati</h1>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="tableContainerEventi">
            <Table striped bordered hover>
              <thead>
                <th>Nr. Ticket</th>
                <th>Nome evento</th>
                <th>Nr. Utenti</th>
                <th>Referente</th>
                <th>Data creazione</th>
                <th>Data scadenza</th>
                <th>Creatore</th>
                <th>Descrizione</th>
                <th>Data creazione su AD</th>
              </thead>
              <tbody>{testo}</tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VisualizzaEventi;
