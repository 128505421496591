import axios from "axios";
//const API_HOST = require("./constants");
axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_API_HOST + "/auth";

const login = (email, password) => {
  return axios
    .post(API_URL + "/login", {
      email,
      password,
    })
    .then((response) => {
      return response.data.accesstoken;
    });
};

const logout = () => {
  return axios.post(API_URL + "/logout");
};

const token = (authHeader) => {
  return axios.post(
    API_URL + "/token",
    {},
    { headers: { "x-auth-token": authHeader } }
  );
};

const refresh = () => {
  return axios.post(API_URL + "/refresh", {}).then((response) => {
    return response.data.rt;
  }).catch(()=>{
    return false;
  });
};

const authService = {
  login,
  logout,
  token,
  refresh,
};

export default authService;
