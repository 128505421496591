import { React, useState, useRef, useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardCustomAPIToken from "./CardCustomAPIToken";
import useCallAPIWithRefresh from "../hooks/useCallAPIWithRefresh";
import AuthContext from "../context/auth-context";
import ApiTokenList from "./ApiTokenList";

const ApiToken = (props) => {
  const ctx = useContext(AuthContext);
  const apiListRef = useRef(null);

  let testo = "";

  const [servizio, setServizio] = useState("");
  const [descrizione,setDescrizione] = useState("");
  const [reloadCount, setreloadCount] = useState(0);
  let [resObj, loading, error] = useCallAPIWithRefresh(
    servizio,
    {
      creatore: ctx.user,
      descrizione: descrizione,
    },
    reloadCount
  );

  if (loading === true) {
    testo = <>CARICAMENTO CONTENUTI IN CORSO....</>;
  }

  if (resObj) {
    const { apitoken} =
      resObj;
    apiListRef.current.incrementa();
    testo = `Nuovo token generato: ${apitoken}`;
  }

  if (error) {
    console.log(error);
  }

  const onButtonClickHandler = (e,desc) => {
    e.preventDefault();
    setDescrizione(desc);
    setServizio("createToken");
    setreloadCount((prevCount) => prevCount + 1);
    //setTimeout(()=>{apiListRef.current.incrementa()},1000);
  };
  //console.log("Conteggio reload: ", reloadCount);
  return (
    <Container fluid>
      <Row>
        <Col className="d-flex justify-content-center">
          <h1>Generazione API Token</h1>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <CardCustomAPIToken
            onButtonClick={onButtonClickHandler}
            titolo="Genera il tuo nuovo API Token"
            testo={testo}
            azione="Crea ora"
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <ApiTokenList ref={apiListRef} />
        </Col>
      </Row>
    </Container>
  );
};

export default ApiToken;
