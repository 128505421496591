import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useRef } from "react";

const CardCustomAPIToken = (props) => {
  const descrizione = useRef();
  return (
    <Card style={{ width: "100%", margin: "1rem", textAlign: "center" }}>
      {/* <img src={logo_unipr_card} /> */}
      {/* <Card.Img  varian="top" src={logo_unipr_card} style={ {height: '30%'} }/> */}
      <Card.Body>
        
        <Card.Text>{props.testo}</Card.Text>
        <Card.Title>{props.titolo}</Card.Title>
        <Form onSubmit={ (e)=> {props.onButtonClick(e,descrizione.current.value);descrizione.current.value=""}}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Descrizione utilizzo del Token</Form.Label>
            <Form.Control
              ref={descrizione}
              type="text"
              placeholder="Inserire una breve descrizione per l'utilizzo del Token"
              required
            />
            <Form.Text className="text-muted">
              La descrizione è utile per identificare il token nel caso lo si
              voglia successivamente eliminare per motivi di sicurezza.
            </Form.Text>
          </Form.Group>
          <Button type="submit" variant="primary">
            {props.azione}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CardCustomAPIToken;
