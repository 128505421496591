import { React, useState, forwardRef, useImperativeHandle, useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import useCallAPIWithRefresh from "../hooks/useCallAPIWithRefresh";
import AuthContext from "../context/auth-context";
import "./ApiTokenList.css";

const ApiTokenList = forwardRef((props, ref) => {
  const ctx = useContext(AuthContext);
  let testo = "";
  const [reloadCount, setreloadCount] = useState(0);
  const [reloadCountD, setreloadCountD] = useState(0);
  const [servizio, setServizio] = useState("");
  const [id,setId] = useState("");

  //console.log("Valore di reloader", props.reloader);

  useImperativeHandle(ref, () => ({
    incrementa() {
      setreloadCount((prevCount) => prevCount + 1);
    },
  }));
// GET per la lista dei token
console.log("RELOADCOUNT: ",reloadCount);
  let [resObj, loading, error] = useCallAPIWithRefresh(
    "getToken",
    {
    },
    reloadCount
  );

// POST per eliminazione di un token
  let [resDelete, loadingDelete, errorDelete] = useCallAPIWithRefresh(
    servizio,
    {
      _id: id
    },
    reloadCountD
  );



  if (loading === true) {
    testo = <>CARICAMENTO CONTENUTI IN CORSO....</>;
  }

  const onDeleteHandler = (_id) => {
    setServizio("deleteToken");
    console.log("Elimino ID: ",_id);
    setId(_id);
    setreloadCountD((prevCount) => prevCount + 1);
    setTimeout(()=>{setreloadCount((p)=>p+1)},500);
  };

  if (resObj) {
    console.log(resObj);
    testo = [...resObj].reverse().map((obj, index) => {
      return (
        <tr key={index}>
          <td>
            <span className="break">{obj.apitoken}</span>
          </td>
          <td>{new Date(obj.dataCreazione).toLocaleString("it-IT")}</td>
          <td>{new Date(obj.dataScadenza).toLocaleString("it-IT")}</td>
          <td>{obj.creatore}</td>
          <td>{obj.descrizione}</td>
          <td><Button onClick={()=>{onDeleteHandler(obj._id)}} variant="danger">Elimina</Button></td>
        </tr>
      );
    });
  }

  if (error) {
    console.log(error);
  }

  //console.log("Conteggio reload: ", reloadCount);
  return (
    <Container fluid>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="tableContainer">
            <Table striped bordered hover>
              <thead>
                <th>Token</th>
                <th>Data creazione</th>
                <th>Data scadenza</th>
                <th>Generato da</th>
                <th>Descrizione</th>
                <th>Azione</th>
              </thead>
              <tbody>
              {testo}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default ApiTokenList;
