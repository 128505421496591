import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

const dettaglioModal = (props) => {

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Riepilogo per l'evento <br></br><b>{props.obj.eventoBreve}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover size="sm">
      <tbody>
        <tr>
          <td>Nome evento</td>
          <td colspan="2">{props.obj.eventoBreve}</td>
        </tr>
        <tr>
          <td>Referente</td>
          <td colspan="2">{props.obj.referente}</td>
        </tr>
        <tr>
          <td>Nr. ticket</td>
          <td colspan="2">{props.obj.nrTicket}</td>
        </tr>
        <tr>
          <td>Nr. utenti</td>
          <td colspan="2">{props.obj.nrUtenti}</td>
        </tr> 
        <tr>
          <td>Descrizione</td>
          <td colspan="2">{props.obj.descrizione}</td>
        </tr>
        <tr>
          <td>Data creazione</td>
          <td colspan="2">{new Date(props.obj.dataCreazione).toLocaleString("it-IT")}</td>
        </tr>
        <tr>
          <td>Data Scadenza</td>
          <td colspan="2">{new Date(props.obj.dataScadenza).toLocaleString("it-IT")}</td>
        </tr>
        <tr>
          <td colspan="2">Utenze</td>
        </tr>         
          {props.obj?.passwords?.map((pass,index)=>{
            let indice = String(index+1);
            indice = indice.padStart(3,"0");
            return(
              <tr>
              <td>{props.obj.eventoBreve}-{indice}@guest.unipr.it</td>
              <td>{pass}</td>
              </tr>
            );
          })}

                                                            
      </tbody>
    </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Chiudi</Button>
        </Modal.Footer>
      </Modal>
    );

};

export default dettaglioModal;