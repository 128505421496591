import axios from "axios";
//const API_HOST = require("./constants");
axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_API_HOST + "/api/service/token";
const API_URL_EVENTI = process.env.REACT_APP_API_HOST + "/api/service/eventi";

const callAPI = async (service, authHeader, objParam) => {
  let result;
  switch (service) {
    case "createToken":
      result = await _createToken(authHeader, objParam);
      break;
    case "createEvento":
      result = await _createEvento(authHeader, objParam);
      break;
    case "getToken":
      result = await _getToken(authHeader);
      break;
    case "getEventi":
      result = await _getEventi(authHeader);
      break;
    case "getNomeEvento":
      result = await _postNomeEvento(authHeader, objParam);
      break;
      case "getNomeReferente":
        result = await _postNomeReferente(authHeader, objParam);
        break;      
    case "deleteToken":
      result = await _deleteToken(authHeader, objParam);
      break;
    default:
      console.log("Nessun servizio invocato!");
  }
  return result;
};

const _createToken = (authHeader, objParam) => {
  const { creatore, descrizione } = objParam;
  return axios
    .post(
      API_URL + "/createToken",
      { creatore, descrizione },
      { headers: { "x-auth-token": authHeader } }
    )
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const _createEvento = (authHeader, objParam) => {
  //const { creatore, descrizione } = objParam;
  return axios
    .post(API_URL_EVENTI + "/createEvento", objParam, {
      headers: { "x-auth-token": authHeader },
    })
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const _postNomeEvento = (authHeader, objParam) => {
  //const { creatore, descrizione } = objParam;
  console.log("post: ",objParam);
  return axios
    .post(API_URL_EVENTI + "/getNomeEvento", objParam, {
      headers: { "x-auth-token": authHeader },
    })
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const _postNomeReferente = (authHeader, objParam) => {
  return axios
    .post(API_URL_EVENTI + "/getUserFromOracle", objParam, {
      headers: { "x-auth-token": authHeader },
    })
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const _getToken = (authHeader) => {
  return axios
    .get(API_URL + "/getToken", { headers: { "x-auth-token": authHeader } })
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const _getEventi = (authHeader) => {
  return axios
    .get(API_URL_EVENTI + "/getEventi", {
      headers: { "x-auth-token": authHeader },
    })
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const _deleteToken = (authHeader, objParam) => {
  const { _id } = objParam;
  return axios
    .post(
      API_URL + "/deleteToken",
      { _id },
      { headers: { "x-auth-token": authHeader } }
    )
    .then((response) => {
      return response.data.message;
    })
    .catch();
};

const apiService = {
  callAPI,
};

export default apiService;
