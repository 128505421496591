import { React } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardCustom from "./CardCustom";
import "./Home.css";

const Home = (props) => {
  return (
    <Container>
      <Row>
      <Col className='d-flex justify-content-center'>
        <h1>Home Page</h1>
        </Col>
      </Row>
      <Row>
      <Col className='d-flex justify-content-center'>
        <div className='homepagecontainer'>
          <p>
          Utilizzare il pulsante di Login (in alto a destra) per autenticarsi e guadagnare il menu completo.
          Dopo l'autenticazione troverete attivo il primo modulo dell'Identity per la gestione delle utenze spot.
          </p>
        </div>
        </Col>
      </Row>      
      {/* <Row className=''>
        <Col className='d-flex justify-content-center'>
          <CardCustom
            titolo="Home"
            testo="Questa è la prima funzione. Buon divertimento!"
            azione="Azione 1"
          />
        </Col>       
      </Row> */}
      
    </Container>
  );
};

export default Home;
