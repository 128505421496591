import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.services";
import axios from "axios";

const AuthContext = React.createContext({
  accesstoken: undefined,
  loggedIn: false,
  onLogout: () => {},
  onLogin: () => {},
  user: undefined
});

export const AuthContextProvider = (props) => {
  const [accesstoken, setAccessToken] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    AuthService.refresh().then((res) => {
      setLoggedIn(res);
      if (res) {
        setUser(localStorage.getItem("user"));
      }
    });
  }, [accesstoken]);

  const logoutHandler = () => {
    AuthService.logout();
    setAccessToken(undefined);
    setLoggedIn((prev)=>{return !prev});
    localStorage.removeItem("user");
  };

  const loginHandler = (at, user) => {
    if (at) {
      setAccessToken(at);
    }
    if (user) {
      setUser(user);
      localStorage.setItem("user", user);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        accesstoken: accesstoken,
        loggedIn: loggedIn,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        user: user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
