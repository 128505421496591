import { React } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import Pulsantone from "./utils/Pulsantone";

const UtenzeSpot = () => {
const history = useHistory();

  const onClickNuovoEvento = () =>{
    history.push("/creaeventi");
  };
  
  const onClickVisualizzaEventi = () =>{
    history.push("/visualizzaeventi");
  };  

  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <h1>Utenze Spot</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <Pulsantone caption="Crea nuovo evento" onClick={onClickNuovoEvento} />
          </Col>
          <Col className="d-flex justify-content-center">
          <Pulsantone caption="Visualizza eventi" onClick={onClickVisualizzaEventi} />
          </Col>          
        </Row>
       
      </Container>
    </>
  );
};

export default UtenzeSpot;
