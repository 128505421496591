import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "react-bootstrap/Badge";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ToastContainer from "react-bootstrap/ToastContainer";
import Notifica from "./Notifica";

import socketIOClient from "socket.io-client";
//const IOENDPOINT = "http://160.78.48.34:5000";
const IOENDPOINT = process.env.REACT_APP_API_HOST;

function Navigazione({ onLogoutClick, loggedIn, user }) {
  const [response, setResponse] = useState([]);
  let history = useHistory();
  let socket = "";

  useEffect(() => {
    socket = socketIOClient(IOENDPOINT, {
      transports: ["polling", "flashsocket"],
    });

    if (loggedIn) {
      socket.on("FromAPI", (data) => {
        console.log("Mi arriva: ", data);
        setResponse((prevres) => {
          return [...prevres, data];
        });
      });
    }
    console.log("Socket: ", socket);

    console.log("Socket listeners: ", socket.listeners);
    return () => {
      socket.off("FromAPI", () => {
        console.log("socket cancellata");
      });
    };
  }, [loggedIn]);

  const chiudi_notifica = (index) => {
    setResponse((prevres) => {
      console.log("in cancellazione: ", prevres);
      //const arr_mod = [...prevres];
      //arr_mod.splice(index,index);
      //return arr_mod;
      return prevres.filter((noti, idx) => idx !== index);
    });
  };

  const logout = () => {
    onLogoutClick();
    window.location.reload();
  };
  return (
    <>
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Link to="/home">
            <Navbar.Brand href="/home" as="div">
              ControlRoom Operations
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/home">
                  Home
                </Nav.Link>
              </Nav.Item>
              {loggedIn && (
                <NavDropdown title="Identity" id="DropDownIdentity">
                  <NavDropdown.Item as={NavLink} to="/utenzespot">
                    Utenze Spot
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {loggedIn && (
                <Nav.Item as="li">
                  <Nav.Link as={NavLink} to="/apitoken">
                    Api Token
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/credits">
                  Credits
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav>
              {loggedIn ? (
                <NavLink className="active" to="/Home">
                  <Nav.Link href="/Home" as="div" onClick={logout}>
                    <Badge bg="light" text="dark">
                      Logout: {user}
                    </Badge>
                  </Nav.Link>
                </NavLink>
              ) : (
                <NavLink className="active" to="/login">
                  <Nav.Link href="/login" as="div">
                    <Badge bg="light" text="dark">
                      Login
                    </Badge>
                  </Nav.Link>
                </NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ToastContainer>
        <Notifica response={response} dismiss={chiudi_notifica} />
      </ToastContainer>
    </>
  );
}

export default Navigazione;
