import { React } from "react";
import "./Ricerca.css";

const Ricerca = ( { nomiEventi, nomeEventoBreve }) => {
const lunghezza = nomiEventi.length;
const eventi_mod = nomiEventi.map((ev)=>{return ev.eventoBreve.substr(0,ev.eventoBreve.indexOf('_'))});

let frase = '';
if(lunghezza == 0){
    frase = 'Il nome evento breve non esiste ancora, e verrà aggiunto il suffisso "_1" ';
    eventi_mod.push(nomeEventoBreve);
}
else {
    frase = 'Nome evento esistente. Verrà aggiunto il suffisso "_'+(lunghezza+1)+' "';
}

let nomiEventiUnici = [...new Set(eventi_mod)];
console.log("EventiUnici: ",nomiEventiUnici);
    return (
        <>
        {nomiEventiUnici.map((evento,key)=>{
            return <li key={key}>{frase}:&nbsp; {evento+'_'+(lunghezza+1)}</li>
        })}
        </>
    );

};

export default Ricerca;