import Toast from "react-bootstrap/Toast";

const Notifica = ({ response, dismiss }) => {

    const chiudi_notifica = (index) => {
        dismiss(index);
    };

  const output = response.map((res, index) => {
    return (
      <Toast key={index} show={res.msg} onClose={()=>chiudi_notifica(index)}>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Notifica</strong>
          <small>{res.data}</small>
        </Toast.Header>
        <Toast.Body>{res.msg}</Toast.Body>
      </Toast>
    );
  });
  return output;
};

export default Notifica;
