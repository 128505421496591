import { React } from "react";
import "./Ricerca.css";

const RicercaReferente = ( { nomiReferenti, onClick }) => {
console.log("Nomi referenti: ",nomiReferenti);
    return (
        <>
        {nomiReferenti.map((nome,key)=>{
            return <li onClick={()=>onClick(`${nome.USERNAME} - ${nome.NOME} ${nome.COGNOME}`)} key={key}>{nome.USERNAME} - {nome.NOME} {nome.COGNOME}
            </li>
        })}
        </>
    );

};

export default RicercaReferente;