import axios from "axios";
//const API_HOST = require("./constants");
axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_API_HOST + "/posts";

const getPublic = () => {
  return axios.get(API_URL + "/public");
};

const getPrivate = (location, authHeader) => {
  return axios.get(API_URL + location, {
    headers: { "x-auth-token": authHeader },
  });
};

const postService = {
  getPublic,
  getPrivate,
};

export default postService;