import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.services";
import Button from 'react-bootstrap/Button';
import "./Login.css";

const Login = ({ onLoginClick }) => {
  const inputEmail = useRef("");
  const inputPassword = useRef("");
  let history = useHistory();
  const [errMsg, seterrMsg] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    //console.log(inputEmail.current.value, inputPassword.current.value);
    setIsLoading(true);
    try {
      await AuthService.login(
        inputEmail.current.value,
        inputPassword.current.value
      ).then(
        (accesstoken) => {
          onLoginClick(accesstoken,inputEmail.current.value);
          inputEmail.current.value = "";
          inputPassword.current.value = "";
          setIsLoading(false);
          history.replace("/Home");
        },
        (error) => {
          
          let msg = error.response.data.errori;
          console.log(msg);
          let msg_mod = msg.map((err, key) => {
            return <><div key={key}>{err.msg}</div><div key={key}>{err.msg2}</div></>;
          });
          setIsLoading(false);
          seterrMsg(msg_mod);
          //console.log(error);
        }
      );
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="form-container">
        
        <form className="form" onSubmit={submitHandler}>
        <h2>Login</h2>
        <label htmlFor="email" >Email: utilizzare nome_admin@sisa.priv</label>
          <input id="email" className="form-input" type="email" placeholder="email" ref={inputEmail} required />
          <label htmlFor="password" >Password</label>
          <input id="password" className="form-input" type="password" placeholder="password" ref={inputPassword} />
          <Button type="submit"  variant="primary">Effettua Login</Button>
        </form>
      </div>
      <div className="msg-container">
        <div className={errMsg && "err-msg" }>{errMsg}</div>
        {isLoading && <div><img className="loading-img" src={require("../assets/img/loading.gif")} alt="Verifica credenziali..." /><p>Verifica credenziali...</p></div>}
      </div>
    </>
  );
};

export default Login;
