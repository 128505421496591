import { useState, useLayoutEffect, useContext } from "react";
import APIService from "../services/api.services";
import AuthService from "../services/auth.services";
import AuthContext from "../context/auth-context";

const useCallWithRefresh = (service, objParam={},reloadCount=0) => {
  const [newToken, setNewToken] = useState(null);
  const [risultato, setRisultato] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const ctx = useContext(AuthContext);

  useLayoutEffect(() => {
    setLoading(true);
    APIService.callAPI(service, ctx.accesstoken, objParam)
      .then((response) => {
        setRisultato(response);
      })
      .catch((error) => {
        const refreshtoken = error.response.data.errors[1].rt;
        if (!refreshtoken) {
          AuthService.logout();
          window.location.reload();
        } else {
          AuthService.token(refreshtoken)
            .then((response) => {
              const newAccessToken = response.data.accesstoken;
              ctx.onLogin(newAccessToken);
              setNewToken(newAccessToken);
            })
            .catch(
              setError({
                msg: "Errore durante generazione nuovo token",
                error: error,
              })
            );
        }
        //setError({ msg: "Errore durante la chiamata API", error: error });
      })
      .finally(setLoading(false));
  }, [newToken, service, ctx, reloadCount]);
  return [risultato, loading, error];
};

export default useCallWithRefresh;
