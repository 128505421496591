import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import logo_unipr_card from '../assets/img/logo_unipr_card.jpg';

const CardCustom = (props) => {
  return (
    <Card style={{ width: '100%', margin: '1rem', textAlign: 'center' }}>
      {/* <img src={logo_unipr_card} /> */}
      {/* <Card.Img  varian="top" src={logo_unipr_card} style={ {height: '30%'} }/> */}
      <Card.Body>
        <Card.Title>{props.titolo}</Card.Title>
        <Card.Text>
          {props.testo}
        </Card.Text>
        <Button onClick={props.onButtonClick}  variant="primary">{props.azione}</Button>
      </Card.Body>
    </Card>

  );
}

export default CardCustom;