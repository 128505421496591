import Button from "react-bootstrap/Button";
import "./Pulsantone.css";
const Pulsantone = ({caption, onClick, descrizione, variant='primary'}) => {

return(
    <div className="">
        <Button className="pulsantone" variant={variant} onClick={onClick}>{caption}</Button>
        <p>{descrizione}</p>
    </div>
);

};

export default Pulsantone;