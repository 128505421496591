import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardCustom from "./CardCustom";
import PostService from "../services/post.service";
import "./Credits.css";

const Credits = (props) => {
  const [posts, setPosts] = useState([]);
  let testo;

  useEffect(() => {
    PostService.getPublic().then(
      (response) => {
        setPosts(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  testo = posts.map((post) => {
    return (
      <div key={post.titolo}>
        <h3>{post.titolo}</h3>
        <p>{post.contenuto}</p>
      </div>
    );
  });

  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center">
          <h1>Credits</h1>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="creditscontainer">
            <p>
              @kappo_admin
            </p>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col className="d-flex justify-content-center">
          <CardCustom
            titolo="Credits"
            testo={testo}
            azione="Azione 3"
          />
        </Col>
      </Row> */}
    </Container>
  );
};

export default Credits;
