import { Switch, Route } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./context/auth-context";
import Login from "./components/Login";
import Home from "./components/Home";
import Identity from "./components/Identity";
import ApiToken from "./components/ApiToken";
import Credits from "./components/Credits";
import UtenzeSpot from "./components/UtenzeSpot/UtenzeSpot";
import CreaEventi from "./components/UtenzeSpot/CreaEventi";
import VisualizzaEventi from "./components/UtenzeSpot/VisualizzaEventi";

import Navigazione from "./components/Navigazione";

function App() {
  const ctx = useContext(AuthContext);
  console.log("Valore di loggedIn: ",ctx.loggedIn);
  return (
    <>
      <Navigazione
        onLogoutClick={ctx.onLogout}
        onLoginClick={ctx.onLogin}
        loggedIn={ctx.loggedIn}
        user={ctx.user}
      />
      <Switch>
        <Route path={["/", "/Home"]} exact>
          <Home />
        </Route>
        {!ctx.loggedIn && (
          <Route path="/login" exact>
            <Login onLoginClick={ctx.onLogin} />
          </Route>
        )}
        {ctx.loggedIn && (
          <Route path="/identity">
            <Identity />
          </Route>
        )}
        {ctx.loggedIn && (
          <Route path="/apitoken">
            <ApiToken />
          </Route>
        )}
        {ctx.loggedIn && (
          <Route path="/utenzespot">
            <UtenzeSpot />
          </Route>
        )}
        {ctx.loggedIn && (
          <Route path="/creaeventi">
            <CreaEventi />
          </Route>
        )}
        {ctx.loggedIn && (
          <Route path="/visualizzaeventi">
            <VisualizzaEventi />
          </Route>
        )}                  
        <Route path="/credits" exact>
          <Credits />
        </Route>
        <Route path="*" exact>
          <Home />
        </Route>
      </Switch>
    </>
  );
}

export default App;
