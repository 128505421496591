import { React } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardCustom from "./CardCustom";
import useCallWithRefresh from "../hooks/useCallWithRefresh";

const Identity = (props) => {
  let [privatePost, loading, error] = useCallWithRefresh("/private");

  let testo = "";

  if (loading === true) {
    testo = <>CARICAMENTO CONTENUTI IN CORSO....</>;
  }

  if (privatePost && privatePost.length > 0) {
    testo = privatePost.map((post) => {
      return (
        <div key={post.titolo}>
          <h3>{post.titolo}</h3>
          <p>{post.contenuto}</p>
        </div>
      );
    });
  }

  if(error){
    console.log(error);
  }

  return (
    <Container>
      <Row>
      <Col className='d-flex justify-content-center'>
        <h1>Identity</h1>
        </Col>
      </Row>      
      <Row>
        <Col className="d-flex justify-content-center">
          <CardCustom titolo="Identity" testo={testo} azione="Azione 2" />
        </Col>
      </Row>
    </Container>
  );
};

export default Identity;
